import classNames from 'classnames'
import React from 'react'

import Button from '../button'
import Icon from '../icon'

import './currency-locale.scss'
import type { Currency, Locale } from '../../types'

type Props = {
  currency: Currency
  locale: Locale
  white?: boolean
  onClick?: (() => void) | null | undefined
}

function glyphName(currency: Currency) {
  if (currency === 'CAD') return 'flag-cdn-light'
  return 'flag-usa-light'
}

export default function CurrencyLocale({
  currency,
  onClick,
  locale,
  white,
}: Props) {
  return (
    // @ts-expect-error TS2322
    <Container
      data-testid="currency-locale"
      className={classNames('currency-locale', 'unstyled', {
        'currency-locale--white': white,
      })}
      onClick={onClick}
      type="unstyled"
    >
      <Icon glyph={glyphName(currency)} />
      <span className="text">
        {currency} / {locale.toUpperCase()}
      </span>
    </Container>
  )
}

type ContainerProps = {
  onClick?: (() => void) | null | undefined
}

function Container({ onClick, ...props }: ContainerProps) {
  if (typeof onClick === 'function') {
    return <Button onClick={onClick} {...props} />
  }

  return <span {...props} />
}
