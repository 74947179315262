import React from 'react'

import { PURCHASABLE_CURRENCIES } from '~/config'
import { i18n } from '~/i18n'

import Button from '../button'
import ContentBlock from '../content-block'
import Modal from '../modal'
import SelectInput from '../select-input'

import './currency-locale-modal.scss'

import type { Currency, Locale } from '../../types'

type State = {
  currency: Currency
  locale: Locale
}

type Props = {
  purchasableCurrencies?: Currency[]
  currency: Currency
  locale: Locale
  onClose: () => void
  onSubmit: (state: State) => void
}

export default class CurrencyLocaleModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      currency: props.currency,
      locale: props.locale,
    }
  }

  handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    this.props.onSubmit(this.state)
  }

  render() {
    const { onClose } = this.props
    const currencies =
      this.props.purchasableCurrencies || PURCHASABLE_CURRENCIES
    return (
      <Modal
        className="amp-currency-locale-modal"
        title={i18n.t('components.currency_locale_modal.title')}
        onClose={onClose}
      >
        <h2 className="currency-modal-title">
          {i18n.t('components.currency_locale_modal.title')}
        </h2>

        <ContentBlock className="currency-modal-subtitle">
          {i18n.t('components.currency_locale_modal.instructions_md')}
        </ContentBlock>

        <form onSubmit={this.handleSubmit} className="currency-modal-form">
          <div className="currency-modal-input-group">
            <SelectInput
              id="currency"
              className="currency-modal-input"
              options={currencies.map((currency) => ({
                value: currency,
                label: i18n.t(
                  `components.currency_locale_modal.currency.options.${currency}`,
                ),
              }))}
              label={i18n.t('components.currency_locale_modal.currency.label')}
              name="currency"
              onChange={({ target: { value } }) =>
                this.setState({
                  currency: value,
                })
              }
              value={this.state.currency}
            />
            <SelectInput
              id="locale"
              className="currency-modal-input"
              options={[
                {
                  value: 'en',
                  label: i18n.t(
                    'components.currency_locale_modal.locale.options.en',
                  ),
                },
                {
                  value: 'fr',
                  label: i18n.t(
                    'components.currency_locale_modal.locale.options.fr',
                  ),
                },
              ]}
              label={i18n.t('components.currency_locale_modal.locale.label')}
              name="locale"
              onChange={({ target: { value } }) =>
                this.setState({
                  locale: value,
                })
              }
              value={this.state.locale}
            />
          </div>

          <div className="currency-modal-actions">
            <Button onClick={this.handleSubmit}>
              {i18n.t('components.currency_locale_modal.submit')}
            </Button>
          </div>
        </form>
      </Modal>
    )
  }
}
