import Cookies from 'js-cookie'
import { useSelector } from 'react-redux'

import { ELIGIBLE_FOR_AFFIRM_ZERO_INTEREST_PRODUCT_CODES } from '~/config'
import { AFFIRM_ZERO_PERCENT_INTEREST_CODE } from '~/config'
import { getAffirmAPI } from '~/utils/affirm'
import { allItems } from '~/utils/cart'

import { globalStrategyBlue } from './global-strategy-blue'
import { globalStrategyDefault } from './global-strategy-default'
import { globalStrategyTremblant } from './global-strategy-tremblant'
import useFeatureFlags from '../use-feature-flags'

import type { AppState } from '~/reducers'

const STRATEGIES = [
  globalStrategyDefault,
  globalStrategyBlue,
  globalStrategyTremblant,
]

const useAffirmConfig = () => {
  const billingAddress = useSelector((state: AppState) =>
    (state.profile?.addresses || []).find(
      (address) => address.type === 'billing',
    ),
  )
  const locale = Cookies.get('locale')
  const cart = useSelector((state: AppState) => state.cart)
  const strategyArguments = { billingAddress, locale, cart }
  const matchingStrategy = STRATEGIES.find((strategy) =>
    strategy.matches(strategyArguments),
  )
  const { affirmZeroPercentInterestEnabled } = useFeatureFlags()
  const isUsAddress = billingAddress?.countryCode === 'US'
  const eligibleProductInCart = allItems(cart).some((cartItem) =>
    ELIGIBLE_FOR_AFFIRM_ZERO_INTEREST_PRODUCT_CODES?.includes(cartItem.type),
  )
  const eligibleForZeroPercentInterest =
    affirmZeroPercentInterestEnabled && isUsAddress && eligibleProductInCart

  return {
    shouldIncludeAffirmScript: !!matchingStrategy,
    validForAffirm: !!matchingStrategy && Boolean(getAffirmAPI()),
    config: matchingStrategy?.config(strategyArguments) || {},
    financingProgram: eligibleForZeroPercentInterest
      ? AFFIRM_ZERO_PERCENT_INTEREST_CODE
      : undefined,
    marketingDataPromoId: eligibleForZeroPercentInterest
      ? AFFIRM_ZERO_PERCENT_INTEREST_CODE
      : undefined,
  }
}

export default useAffirmConfig
