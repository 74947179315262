import { camelizeKeys, decamelizeKeys } from 'humps'

import {
  serializeCartItemCount,
  serializeCartAddon,
  serializeChangeCartParticipant,
  serializeCartPromotion,
} from '~/serializers/cart'
import { creditCardSerializer } from '~/serializers/credit-card'
import orderSerializer from '~/serializers/order'
import { serializePricingWindow } from '~/serializers/pricing-window'
import { serializeProductTranslations } from '~/serializers/product-translations'
import { serializeProfile } from '~/serializers/profile'
import {
  serializeCreateReservation,
  serializeUpdateReservation,
} from '~/serializers/reservation'
import { serializeReservationCart } from '~/serializers/reservation-cart'
import { serializeVoucherShare } from '~/serializers/voucher-share'
import getAPICore from '~/utils/api'
import { authedFetch } from '~/utils/fetch'
import HTTPError from '~/utils/http-error'
import { reportGroupedError } from '~/utils/logger'

import getAdminAPIEndpoints from './get-admin-api-endpoints'

import type {
  Admin,
  ProfileSearch,
  OnboardingSearch,
  CreditType,
  ProductTranslations,
  BenefitTranslations,
  PricingWindow,
} from '~/admin/types'
import type {
  AdminAPI,
  APICore,
  NewCreditCard,
  PartialAdmin,
  ProductVariant,
  ProfileDetailsEdit,
  Reservation,
  ProfileAuditEventsV2Params,
  VoucherShareRequest,
} from '~/types'

const ADMIN_LOGIN_PATH = '/admin'

function adminAuthedFetch(
  url: string,
  options: Record<string, any>,
): Promise<Response> {
  return authedFetch(url, options, ADMIN_LOGIN_PATH)
}

export default function getAdminAPI(guestId = ''): AdminAPI {
  const api: APICore = getAPICore(adminAuthedFetch)
  const endpoints = getAdminAPIEndpoints(guestId)

  const createResort = (data: Record<string, any>): Promise<Response> => {
    return api.post(endpoints.resortsPath, {
      body: JSON.stringify({
        data,
      }),
    })
  }

  const updateResort = (data: Record<string, any>): Promise<Response> => {
    return api.put(endpoints.resortPath(data.id), {
      body: JSON.stringify({
        data,
      }),
    })
  }

  const createProduct = (data: Record<string, any>): Promise<Response> => {
    return api.post(endpoints.productsPath, {
      body: JSON.stringify(decamelizeKeys(data)),
    })
  }

  const createResortAuthorizationCategory = (
    data: Record<string, any>,
  ): Promise<Response> => {
    return api.post(endpoints.resortAuthorizationCategoriesPath, {
      body: JSON.stringify({
        data,
      }),
    })
  }

  const updateResortAuthorizationCategory = (
    data: Record<string, any>,
  ): Promise<Response> => {
    return api.put(endpoints.resortAuthorizationCategoryPath(data.id), {
      body: JSON.stringify({
        data,
      }),
    })
  }

  const createOrUpdateResortAuthorizationCategory = (
    data: Record<string, any>,
  ): Promise<Response> => {
    return data.id
      ? updateResortAuthorizationCategory(data)
      : createResortAuthorizationCategory(data)
  }

  const createReservation = (data: Reservation): Promise<Response> => {
    return api.post(endpoints.reservationsPath, {
      body: JSON.stringify(serializeCreateReservation(data)),
    })
  }

  const updateReservation = (data: Reservation): Promise<Response> => {
    if (!data.id) return Promise.reject()

    return api.put(endpoints.reservationPath(data.id), {
      body: JSON.stringify(serializeUpdateReservation(data)),
    })
  }

  const createOrUpdateReservation = (data: Reservation): Promise<Response> => {
    if (data.id) return updateReservation(data)

    return createReservation(data)
  }

  return {
    core: api,

    addUpgradesToCart(data: Record<number, string>): Promise<Response> {
      return api.post(endpoints.addUpgradesToCartPath, {
        body: JSON.stringify({
          data,
        }),
      })
    },

    updateCartItemCount(
      productVariant: ProductVariant,
      count,
    ): Promise<Response> {
      return api.patch(endpoints.cartItemsPath, {
        body: JSON.stringify(serializeCartItemCount(productVariant, count)),
      })
    },

    addCartAddon(internalId: string, addonType: string): Promise<Response> {
      return api.post(endpoints.cartAddonsPath, {
        body: JSON.stringify(serializeCartAddon(internalId, addonType)),
      })
    },

    removeCartAddon(internalId: string, addonType: string): Promise<Response> {
      return api.delete(endpoints.cartAddonsPath, {
        body: JSON.stringify(serializeCartAddon(internalId, addonType)),
      })
    },

    changeCartParticipant(
      internalId: string,
      profileId: string | null | undefined,
    ): Promise<Response> {
      return api.patch(endpoints.cartItemsAssignPath(internalId), {
        body: JSON.stringify(serializeChangeCartParticipant(profileId)),
      })
    },

    addCartPromoCode(code: string): Promise<Response> {
      return api.post(endpoints.cartPromotionsPath, {
        body: JSON.stringify(serializeCartPromotion(code)),
      })
    },

    removeCartPromoCode(code: string): Promise<Response> {
      return api.delete(endpoints.cartPromotionsPath, {
        body: JSON.stringify(serializeCartPromotion(code)),
      })
    },

    getProfileAuditEventsV2(
      params: ProfileAuditEventsV2Params,
    ): Promise<Response> {
      return api.get(endpoints.profileAuditEventsV2Path(params))
    },

    getProfile(profileId: string): Promise<Response> {
      return api.get(endpoints.profilePath(profileId))
    },

    getProfileDetail(profileId: string): Promise<Response> {
      return api.get(endpoints.profileDetailPath(profileId))
    },

    searchProfiles(params: ProfileSearch): Promise<Response> {
      return api.get(endpoints.profilesPath(params))
    },

    searchOnboardings(params: OnboardingSearch): Promise<Response> {
      return api.get(endpoints.onboardingsPath(params))
    },

    createProfile(profile: ProfileDetailsEdit): Promise<Response> {
      return api.post(endpoints.guestProfilesPath, {
        body: JSON.stringify(serializeProfile(profile)),
      })
    },

    setCartCreditCard(
      creditCard: NewCreditCard,
      saveCardOnFile: boolean,
    ): Promise<Response> {
      return api.post(endpoints.cartCreditCardPath, {
        body: JSON.stringify(
          creditCardSerializer({
            creditCard,
            saveCardOnFile,
          }),
        ),
      })
    },

    createOrder(termsAcceptedAt: number): Promise<Response> {
      return api.post(endpoints.ordersPath, {
        body: JSON.stringify(
          orderSerializer({
            termsAcceptedAt,
          }),
        ),
      })
    },

    getOrder(orderId: number | string): Promise<Response> {
      return api.get(endpoints.orderPath(orderId), {})
    },

    updateOrder(
      orderId: number | string,
      data: Record<string, any>,
    ): Promise<Response> {
      return api.put(endpoints.orderPath(orderId), {
        body: JSON.stringify({
          data: decamelizeKeys(data),
        }),
      })
    },

    getOrderProducts(orderId: string): Promise<Response> {
      return api.get(endpoints.getOrderProductsPath(orderId), {})
    },

    getProfileRedemptions(profileId: string): Promise<Response> {
      return api.get(endpoints.profileRedemptionsPath(profileId))
    },

    getProfileMedia(
      profileId: string,
      activeMediaOnly?: boolean,
    ): Promise<Response> {
      return api.get(endpoints.profileMediaPath(profileId, activeMediaOnly))
    },

    upgradeCart(profileProductIds: string[]): Promise<Response> {
      return api.post(endpoints.cartUpgradePath, {
        body: JSON.stringify({
          data: profileProductIds,
        }),
      })
    },

    createOverrideAffiliation({
      profileId,
      affiliationType,
    }: {
      profileId: string
      affiliationType: string
    }): Promise<Response> {
      return api.post(endpoints.overrideAffiliationsPath(profileId), {
        body: JSON.stringify({
          data: {
            affiliation_type: affiliationType,
          },
        }),
      })
    },

    getProfileProducts(includeInactive = false): Promise<Response> {
      return api.get(endpoints.profileProductsPath(includeInactive))
    },

    getProfileProductsForProfile(
      profileId: string,
      includeInactive = false,
    ): Promise<Response> {
      return api.get(
        endpoints.profileProductsForProfilePath(profileId, includeInactive),
      )
    },

    updateProfileProductDeferment({
      profileProductId,
      passDeferred,
    }: {
      profileProductId: number
      passDeferred: boolean
    }): Promise<Response> {
      return api.patch(endpoints.profileProductUpdatePath(profileProductId), {
        body: JSON.stringify({
          data: {
            pass_deferred: passDeferred,
          },
        }),
      })
    },

    getProducts(): Promise<Response> {
      return api.get(endpoints.productsPath)
    },

    getProductFamilies(): Promise<Response> {
      return api.get(endpoints.productFamiliesPath)
    },

    getBenefit(benefitId: number): Promise<Response> {
      return api.get(endpoints.benefitPath(benefitId))
    },

    getBenefitProducts(benefitId: number): Promise<Response> {
      return api.get(endpoints.benefitProductsPath(benefitId))
    },

    getBenefitTranslations(benefitId: number): Promise<Response> {
      return api.get(endpoints.benefitTranslationsPath(benefitId))
    },

    cloneBenefits(data: {
      benefitIds: number[]
      seasonId: number
    }): Promise<Response> {
      return api.post(endpoints.benefitsClonePath, {
        body: JSON.stringify({ data: decamelizeKeys(data) }),
      })
    },

    updateBenefitProducts(
      benefitId: number,
      productIds: number[],
    ): Promise<Response> {
      return api.put(endpoints.benefitProductsPath(benefitId), {
        body: JSON.stringify({
          data: { id: benefitId, product_ids: productIds },
        }),
      })
    },

    updateBenefitTranslations(
      benefitId: number,
      data: BenefitTranslations,
    ): Promise<Response> {
      return api.put(endpoints.benefitTranslationsPath(benefitId), {
        body: JSON.stringify({ data: decamelizeKeys(data) }),
      })
    },

    updateProductResorts(
      productId: number,
      data: Record<string, any>,
    ): Promise<Response> {
      return api.put(endpoints.productResortsPath(productId), {
        body: JSON.stringify({
          data: decamelizeKeys(data),
        }),
      })
    },

    createProductPricingWindow(
      productId: number,
      data: PricingWindow,
    ): Promise<Response> {
      return api.post(endpoints.productPricingWindowsPath(productId), {
        body: JSON.stringify(serializePricingWindow(data)),
      })
    },

    getProductPricingWindows(productId: number): Promise<Response> {
      return api.get(endpoints.productPricingWindowsPath(productId))
    },

    editProductPricingWindow(
      pricingWindowId: number,
      productId: number,
      data: PricingWindow,
    ): Promise<Response> {
      return api.put(
        endpoints.productPricingWindowsUpdatePath(pricingWindowId, productId),
        {
          body: JSON.stringify(serializePricingWindow(data)),
        },
      )
    },

    deleteProductPricingWindow(
      pricingWindowId: number,
      productId: number,
      data: PricingWindow,
    ): Promise<Response> {
      return api.delete(
        endpoints.productPricingWindowsUpdatePath(pricingWindowId, productId),
        {
          body: JSON.stringify(serializePricingWindow(data)),
        },
      )
    },

    getProductEntitlementTypes(productId: number): Promise<Response> {
      return api.get(endpoints.productEntitlementTypesPath(productId))
    },

    createDuplicateProduct(productId: number): Promise<Response> {
      return api.post(endpoints.productDuplicatePath(productId))
    },

    createProductEntitlementType(
      productId: number,
      data: Record<string, any>,
    ): Promise<Response> {
      return api.post(endpoints.productEntitlementTypesPath(productId), {
        body: JSON.stringify({
          data: decamelizeKeys(data),
        }),
      })
    },

    updateEntitlementType(
      entitlementTypeId: number,
      data: Record<string, any>,
    ): Promise<Response> {
      return api.put(endpoints.entitlementTypePath(entitlementTypeId), {
        body: JSON.stringify({
          data: decamelizeKeys(data),
        }),
      })
    },

    deleteEntitlementType(entitlementTypeId: number): Promise<Response> {
      return api.delete(endpoints.entitlementTypePath(entitlementTypeId))
    },

    getResorts(): Promise<Response> {
      return api.get(endpoints.resortsPath)
    },

    getResort(resortId: number): Promise<Response> {
      return api.get(endpoints.resortPath(resortId))
    },

    createResort,
    updateResort,

    createResortCapacity(
      resortId: number | string,
      data: Record<string, any>,
    ): Promise<Response> {
      return api.post(endpoints.resortCapacitiesPath(resortId), {
        body: JSON.stringify({
          data: decamelizeKeys(data),
        }),
      })
    },

    updateResortCapacity(
      resortId: number | string,
      resortCapacityId: number | string,
      data: Record<string, any>,
    ): Promise<Response> {
      return api.put(endpoints.resortCapacityPath(resortId, resortCapacityId), {
        body: JSON.stringify({
          data: decamelizeKeys(data),
        }),
      })
    },

    deleteResortCapacity(
      resortId: number | string,
      resortCapacityId: number | string,
    ): Promise<Response> {
      return api.delete(
        endpoints.resortCapacityPath(resortId, resortCapacityId),
      )
    },

    createProduct,

    getResortAuthorizationCategories(): Promise<Response> {
      return api.get(endpoints.resortAuthorizationCategoriesPath)
    },

    createResortAuthorizationCategory,
    updateResortAuthorizationCategory,
    createOrUpdateResortAuthorizationCategory,

    getGroup(): Promise<Response> {
      return api.get(endpoints.groupPath)
    },

    getReservationAvailability(resortId: number | string): Promise<Response> {
      return api.get(endpoints.reservationAvailabilityPath(resortId))
    },

    getReservations(): Promise<Response> {
      return api.get(endpoints.reservationsPath)
    },

    cancelReservation(reservationId: number): Promise<Response> {
      return api.delete(endpoints.reservationPath(reservationId))
    },

    createReservation,
    updateReservation,
    createOrUpdateReservation,

    confirmReservationCart(data: {
      termsConditionsAcceptedAt: Date | null
    }): Promise<Response> {
      return api.post(endpoints.reservationCartConfirmPath, {
        body: JSON.stringify(serializeReservationCart(data)),
      })
    },

    getReservationCart(): Promise<Response> {
      return api.get(endpoints.reservationCartPath)
    },

    getReservationExceptions(profileId: string): Promise<Response> {
      return api.get(endpoints.reservationExceptionsPath(profileId))
    },

    updateReservationExceptions(
      profileId: string,
      resortIds: number[],
    ): Promise<Response> {
      return api.put(endpoints.reservationExceptionsPath(profileId), {
        body: JSON.stringify({
          data: {
            resort_ids: resortIds,
          },
        }),
      })
    },

    cancelResortReservations(
      resortId: number,
      dates: string[],
    ): Promise<Response> {
      return api.post(endpoints.resortReservationsCancelPath(resortId), {
        body: JSON.stringify({
          data: {
            dates,
          },
        }),
      })
    },

    getGuest(): Promise<Response> {
      return api.get(endpoints.guestPath)
    },

    updateGuest({
      email,
      locale,
    }: {
      email?: string
      locale?: string
    }): Promise<Response> {
      const data = {} as Record<string, string>

      if (email) data.email = email
      if (locale) data.locale = locale

      return api.put(endpoints.guestPath, {
        body: JSON.stringify({
          data,
        }),
      })
    },

    createHotlists({
      entitlementIds,
      from,
      to,
    }: {
      entitlementIds: number[]
      from: string | null
      to: string | null
    }): Promise<Response> {
      return api.post(endpoints.hotlistsPath, {
        body: JSON.stringify({
          data: {
            entitlement_ids: entitlementIds,
            from: from,
            to: to,
          },
        }),
      })
    },

    getCredits(profileId: string): Promise<Response> {
      return api.get(endpoints.creditsPath(profileId))
    },

    getCreditTypes(): Promise<Response> {
      return api.get(endpoints.creditTypesPath)
    },

    getCreditType(creditTypeId: number): Promise<Response> {
      return api.get(endpoints.creditTypePath(creditTypeId))
    },

    updateCreditType(
      creditTypeId: number,
      data: CreditType,
    ): Promise<Response> {
      return api.put(endpoints.creditTypePath(creditTypeId), {
        body: JSON.stringify({
          data: decamelizeKeys(data),
        }),
      })
    },

    newCreditType(creditType: CreditType): Promise<Response> {
      return api.post(endpoints.creditTypesPath, {
        body: JSON.stringify(
          decamelizeKeys({
            data: creditType,
          }),
        ),
      })
    },

    dismissCartItemCredit(internalId: string): Promise<Response> {
      return api.delete(endpoints.cartItemsCreditDismissPath(internalId))
    },

    resetCredit(creditId: number): Promise<Response> {
      return api.put(endpoints.creditResetPath(creditId))
    },

    revokeCredit(creditId: number, notes: string): Promise<Response> {
      return api.put(endpoints.creditRevokePath(creditId), {
        body: JSON.stringify({
          metadata: {
            notes,
          },
        }),
      })
    },

    createCredit(
      creditData: {
        profileProductId: number | string
        creditTypeId: number | string
        amountUsd: number | string | null
        amountCad: number | string | null
      },
      notes: string,
    ): Promise<Response> {
      return api.post(endpoints.creditCreatePath, {
        body: JSON.stringify({
          data: decamelizeKeys(creditData),
          metadata: {
            notes,
          },
        }),
      })
    },

    applyPromotionPrompt(
      internalId: string,
      {
        productId,
        variant,
      }: {
        productId: string
        variant: string
      },
    ): Promise<Response> {
      return api.post(endpoints.cartItemPromotionPromptPath(internalId), {
        body: JSON.stringify({
          data: {
            product_id: productId,
            variant,
          },
        }),
      })
    },

    getProductCreditExpirations(): Promise<Response> {
      return api.get(endpoints.productCreditExpirationsPath)
    },

    createProductCreditExpirations(
      productCodes: string[],
      expirationDate: string,
    ): Promise<Response> {
      return api.post(endpoints.productCreditExpirationsPath, {
        body: JSON.stringify({
          data: {
            product_codes: productCodes,
            expiration_date: expirationDate,
          },
        }),
      })
    },

    deleteProductCreditExpiration(
      productCreditExpirationId: number,
    ): Promise<Response> {
      return api.delete(
        endpoints.deleteProductCreditExpirationsPath(productCreditExpirationId),
      )
    },

    fullyExpireProductCreditExpiration(
      productCreditExpirationId: number,
    ): Promise<Response> {
      return api.post(
        endpoints.fullyExpireProductCreditExpirationPath(
          productCreditExpirationId,
        ),
      )
    },

    getBackdating(): Promise<Response> {
      return api.get(endpoints.backdatingPath)
    },

    createBackdating(date: string): Promise<Response> {
      return api.post(endpoints.backdatingPath, {
        body: JSON.stringify({
          data: {
            date,
          },
        }),
      })
    },

    deleteProduct(productId: number): Promise<Response> {
      return api.delete(endpoints.productPath(productId))
    },

    getProfileOrders(profileId: string): Promise<Response> {
      return api.get(endpoints.profileOrdersPath(profileId))
    },

    acknowledgeCartChange(): Promise<Response> {
      return api.put(endpoints.acknowledgeCartChangePath)
    },

    getProfileWaivers(profileId: string): Promise<Response> {
      return api.get(endpoints.profileWaiversPath(profileId))
    },

    reprocessOrders(orderIds: number[]): Promise<Response> {
      return api.post(endpoints.reprocessOrdersPath, {
        body: JSON.stringify({
          data: {
            order_ids: orderIds,
          },
        }),
      })
    },

    getVouchersSharedFromProfileProduct(
      profileProductId: number,
    ): Promise<Response> {
      return api.get(
        endpoints.vouchersSharedFromProfileProductPath(profileProductId),
      )
    },

    getVouchersSharedToProfile(profileId: string): Promise<Response> {
      return api.get(endpoints.vouchersSharedToProfilePath(profileId))
    },

    shareVoucher(data: VoucherShareRequest): Promise<Response> {
      return api.post(endpoints.voucherSharePath, {
        body: JSON.stringify(serializeVoucherShare(data)),
      })
    },

    returnVoucher(voucherShareId: number, note: string): Promise<Response> {
      return api.post(endpoints.returnVoucherSharePath(voucherShareId), {
        body: JSON.stringify({
          data: {
            note,
          },
        }),
      })
    },

    emailCartLink(): Promise<Response> {
      return api.post(endpoints.emailCartLinkPath)
    },

    getProfileGroupMemberships(profileId: string): Promise<Response> {
      return api.get(endpoints.profileGroupMembershipsPath(profileId))
    },

    getParticipantReservations(profileId: string): Promise<Response> {
      return api.get(endpoints.participantReservationsPath(profileId))
    },

    reportCheckoutInitiation(): Promise<Response> {
      return api.post(endpoints.facebookConversionsPath, {
        body: JSON.stringify({
          data: {
            event_type: 'initiate_checkout',
          },
        }),
      })
    },

    getRedeemableVouchers(): Promise<Response> {
      return api.get(endpoints.redeemableVouchersPath)
    },

    getPromotionCode(promotionCodeId: number): Promise<Response> {
      return api.get(endpoints.promotionCodePath(promotionCodeId))
    },

    removeAllCardsOnFile(): Promise<Response> {
      return api.delete(endpoints.removeAllCardsOnFilePath)
    },

    getProductTranslations(productId: number): Promise<Response> {
      return api.get(endpoints.productTranslationsPath(productId))
    },

    updateProductTranslations(
      productId: number,
      translations: ProductTranslations,
    ): Promise<Response> {
      return api.put(endpoints.productTranslationsPath(productId), {
        body: JSON.stringify({
          data: serializeProductTranslations(translations),
        }),
      })
    },

    getPermissionsForResortManager(): Promise<Response> {
      return api.get(endpoints.resortManagerPermissibleResortsPath)
    },

    getAdmin(adminId: number): Promise<Response> {
      return api.get(endpoints.adminPath(adminId))
    },

    getAdmins(): Promise<Response> {
      return api.get(endpoints.adminsPath)
    },

    createAdmin(data: Admin): Promise<Response> {
      return api.post(endpoints.adminsPath, {
        body: JSON.stringify(decamelizeKeys(data)),
      })
    },

    updateAdmin(adminId: number, data: PartialAdmin): Promise<Response> {
      return api.put(endpoints.adminPath(adminId), {
        body: JSON.stringify(decamelizeKeys(data)),
      })
    },

    createPasswordResetRequest(email: string): Promise<Response> {
      return api.post(endpoints.passwordResetRequestPath, {
        body: JSON.stringify({
          email: email,
        }),
      })
    },

    getCallCenters(): Promise<Response> {
      return api.get(endpoints.callCentersPath)
    },

    getSalesAgentReport(queryParams: {
      dateFrom: string
      dateTo: string
      callCenterId: number | null
      adminId: number | null
    }): Promise<Response> {
      return api.get(endpoints.salesAgentReportPath(queryParams))
    },

    getCreditCardOnFile(profileId): Promise<Response> {
      return api.get(endpoints.creditCardOnFilePath(profileId))
    },

    useCreditCardOnFile(cvc: string): Promise<Response> {
      return api.post(endpoints.useCreditCardOnFilePath, {
        body: JSON.stringify({
          data: {
            cvc,
          },
        }),
      })
    },

    getSelfPurchaseReport(queryParams: {
      dateFrom: string
      dateTo: string
      productCodes: string
    }): Promise<Response> {
      return api.get(endpoints.selfPurchaseReportPath(queryParams))
    },

    getCurrentAdmin(): Promise<Response> {
      return api.get(endpoints.currentAdminPath)
    },

    beginGuestImpersonation(guestId: string): Promise<Response> {
      return api.post(endpoints.guestImpersonationPath, {
        body: JSON.stringify({ data: { guest_id: guestId } }),
      })
    },

    endGuestImpersonation(): Promise<Response> {
      return api.delete(endpoints.guestImpersonationPath)
    },

    getOauthApplication(applicationId: number | string): Promise<Response> {
      return api.get(endpoints.oauthApplicationPath(applicationId))
    },

    getOauthApplicationFormData(
      applicationId: number | string,
    ): Promise<Response> {
      return api.get(endpoints.oauthApplicationFormDataPath(applicationId))
    },

    getOauthApplications(): Promise<Response> {
      return api.get(endpoints.oauthApplicationsPath)
    },

    deleteOauthApplication(applicationId: number): Promise<Response> {
      return api.delete(endpoints.oauthApplicationPath(applicationId))
    },

    recoverOauthApplication(applicationId: number): Promise<Response> {
      return api.put(endpoints.oauthApplicationPath(applicationId), {
        body: JSON.stringify({
          recover: true,
        }),
      })
    },

    updateOauthApplication(data: Record<string, any>): Promise<Response> {
      return api.put(endpoints.oauthApplicationPath(data.id), {
        body: JSON.stringify({ data }),
      })
    },

    removeCartItem(internalId: string) {
      return api.delete(endpoints.cartItemPath(internalId))
    },

    updateProfile(
      profileId: string,
      data: {
        firstName?: string
        lastName?: string
        dob?: string
        email?: string
      },
    ) {
      return api.put(endpoints.profilePath(profileId), {
        body: JSON.stringify({
          data: decamelizeKeys(data),
        }),
      })
    },
  }
}

export async function adminApiFetch(
  url: string,
  options: Record<string, any> = {},
) {
  try {
    const response = await adminAuthedFetch(url, options)

    if (response.status === 204) {
      return
    } else if (response.status === 422) {
      const { errors } = await response.json()
      throw camelizeKeys(errors)
    } else if (response.ok) {
      const { data } = await response.json()
      return camelizeKeys(data)
    } else {
      const text = await response.text()
      throw new HTTPError('fetch failed', response, text)
    }
  } catch (error) {
    if (['HTTPError', 'TypeError'].includes(error.name)) {
      reportGroupedError(url, error)
    }
    throw error
  }
}
